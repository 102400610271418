<template>
    <div>
        <!-- Dasboard heading and date-picker -->
        <div class="d-flex flex-wrap justify-content-between">
        <h3 class="mt-4">Dashboard</h3>
        <div class="mt-1">
                    <label for="" class="mt-0 font-weight-bold font-12">Date Range </label>
                    <div id="daterange" class="daterange" style="width:340px !important;">
                        <i class="fa fa-calendar place"></i>
                        <span class="daterange-dis font ml-2"></span> 
                    </div>
                </div>
        </div>
        <!-- Dashboard info cards -->
        <div class="mt-5">
        <b-card-group deck class="my-3">
            <b-card align="center"
                    border-variant="primary"
                    header="Companies Report"
                    header-bg-variant="primary"
                    header-text-variant="white">
                <div class="d-flex flex-wrap justify-content-around">
                    <div class="mt-1">
                        <b-card border-variant="success" align="center">
                            <p class="card-text"><strong>Companies With Screenshot </strong></p>
                            <p class="card-text">{{companyWithScreenshot}} {{calculatePercentageCompany(companyWithScreenshot)}}</p>
                        </b-card>
                    </div>
                    <div class="mt-1">
                        <b-card border-variant="success" align="center">
                            <p class="card-text"><strong>Companies With Team Members</strong></p>
                            <p class="card-text">{{companyWithTeamMember}} {{calculatePercentageCompany(companyWithTeamMember)}}</p>
                        </b-card>
                    </div>
                    <div class="mt-1">
                        <b-card border-variant="success" align="center">
                            <p class="card-text"><strong>Total Companies </strong></p>
                            <p class="card-text">{{totalCompanies}}</p>
                        </b-card>
                    </div>
                </div>
            </b-card>
        </b-card-group>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import $ from "jquery";
    import moment from "moment";
    import "daterangepicker/daterangepicker.js";
    import "daterangepicker/daterangepicker.css";
    import _ from 'lodash';
    export default {
        name: "Dashboard",
        data() {
            return {
                start: moment().subtract(7, "days"),
                end: moment().subtract(0, "days"),
                start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
                end_date: moment().subtract(0, "days").format("YYYY-MM-DD"),
                // start_date: moment()
                //     .subtract(29, "days")
                //     .format("YYYY-MM-DD"),
                // end_date: moment().format("YYYY-MM-DD"),
                companyData : null,
                fields: {
                    capture_date: {
                        label: "Date",
                        thStyle: {
                            width: "45px"
                        }
                    },
                    count: {
                        label: "Count",
                        thStyle: {
                            width: "140px"
                        }
                    }
                }
            };
        },
        computed: {
            ...mapState(["companies", "screenshots"]),
            widgets() {
                return this.$store.state.dashboard_widgets;
            },
            isBusy() {
                return this.$store.state.isBusy;
            },
            companyWithScreenshot(){
                let company  = 0;
                if(this.companyData != null && this.companyData.length > 0){
                    company = this.companyData.filter(data => data.screenshot_view > 0);
                    company = company.length
                }
                return company;
            },
            companyWithTeamMember(){
                let company  = 0;
                if(this.companyData != null && this.companyData.length > 0){
                    company = this.companyData.filter(data => data.team_members > 1);
                    company = company.length
                }
                return company;
            },
            totalCompanies(){
                let company  = 0;
                if(this.companyData != null && this.companyData.length > 0){
                    company = this.companyData.length;
                }
                return company;
            }

        },
        mounted() {
            this.dateRangeStart();
            // this.getDashboardData();
            // this.getSceenshots();
            this.getDashboardCompany()
        },

        methods :{

            calculatePercentageCompany(calculatedData){
                if(this.companyData !=null && this.companyData.length > 0){
                    if(calculatedData > 0){
                        calculatedData =  (calculatedData/this.companyData.length)*100
                        calculatedData = Math.round(calculatedData)
                    }
                    return `(${calculatedData}%)`;
                }
            },
            getDashboardCompany(){
                let data = {
                    from: this.start_date,
                    to: this.end_date,
                };
                this.$store.dispatch("get_dashboard_company",data).then(response => {
                    this.companyData = response.data.companies;
                }).catch(error => {
                });
            },

            dateRangeStart() {
                if(this.start.format('DD/MM/YYYY') == '01/01/2017') {
                    $('#daterange span').html('All time');
                }else {
                    $('#daterange span').html(this.start.format('MMMM D, YYYY') + ' - ' + this.end.format('MMMM D, YYYY'));
                }
                $(".daterange").daterangepicker(
                    {
                        startDate: this.start,
                        endDate: this.end,
                        opens: "left",
                        ranges: {
                            Today: [moment(), moment()],
                            Yesterday: [
                                moment().subtract(1, "days"),
                                moment().subtract(1, "days")
                            ],
                            "Last 7 Days": [moment().subtract(6, "days"), moment()],
                            "This Month": [moment().startOf("month"), moment().endOf("month")],
                            "Last Month": [
                                moment()
                                    .subtract(1, "month")
                                    .startOf("month"),
                                moment()
                                    .subtract(1, "month")
                                    .endOf("month")
                            ],
                            // "Last Year": [moment().subtract(1, "year"), moment()],
                            // 'All time': [moment('2017-01-01'), moment()],
                        }
                    },
                    this.dateRangeChange
                );
            },

            dateRangeChange(start, end) {
                $(".daterange span")
                    .html(start.format("MMM D, YYYY") + " - " + end.format("MMM D, YYYY"))
                    .addClass("pl-2 pr-1");
                this.start_date = start.format("YYYY-MM-DD");
                this.end_date = end.format("YYYY-MM-DD");
                this.getDashboardCompany();

            },

            getDashboardData(){
                this.$store.dispatch("get_dashboard_data");
            },

            getSceenshots(){
                let data = {
                    from: this.start_date,
                    to: this.end_date,
                };
                this.$store.dispatch("getScreenshots",data);
            }

        }
    };
</script>

<style scoped>
    .card-header bg-primary text-white{
        margin-left: 0px;
    }
    .margin-left{
        margin-left: 800px;
    }
</style>
